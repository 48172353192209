import { Model, Store } from '@code-yellow/spider';
import { observable, computed } from 'mobx';
import { Truck } from './Truck';
import { TachoEvent } from './TachoEvent';
import { TachoStatistics } from 'store/TachoStatistics';
import { DriverDataSourceStore } from 'store/DriverDataSource';
import { User } from 'store/User';
import ExternalDataSource, { ExternalDriverDataSourceList } from './enums/ExternalDataSource';

export class Driver extends Model {
    static backendResourceName = 'driver';

    @observable id: number | null = null;
    @observable name = '';
    @observable phoneNumber = '';
    @observable weekendPause = '';

    @observable truck = this.relation(Truck);
    @observable currentTachoEvent = this.relation(TachoEvent);
    @observable currentTachoStatistics = this.relation(TachoStatistics);
    @observable driverSources = this.relation(DriverDataSourceStore);
    @observable user = this.relation(User);

    @computed get hasTachoEventProvider() {
        if (!this.driverSources.models.length) {
            return null
        }
        return this.driverSources.find( source => source.activeTachoEvent === true && source.dataExternalId != null && source.dataExternalId !== '');
    }
    @computed get hasMessageProvider() {
        if (!this.driverSources.models.length) {
            return null
        }
        return this.driverSources.models.some(source =>
            source.activeMessage && (source.dataSource === ExternalDataSource.DRIVERAPP || (source.dataExternalId !== '' && source.dataExternalId != null))
        )
    }

    @computed get getDriverTachoExternalId() {
        if (!this.driverSources.models.length) {
            return null
        }
        const driverSources = this.driverSources.find( source => source.activeTachoEvent === true && source.dataExternalId != null && source.dataExternalId !== '')
        if (driverSources) {
            return driverSources.dataExternalId;
        }

        return null;
    }

    @computed get getDriverTachoSourceName() {
        if (!this.driverSources.models.length) {
            return null
        }
        const driverSources = this.driverSources.find( source => source.activeTachoEvent === true && source.dataSource != null && source.dataSource !== '')
        if (driverSources) {
            return driverSources.dataSource;
        }

        return null;
    }

    getDataSources() {
        return this.driverSources;
    }

    createUser({ email, password }) {
        return this.api.put(`${this.url}create_user/`, {
            email: email,
            password: password,
        });
    }

    changeDriverData({ email, password }) {
        return this.api.put(`${this.url}change_driver_data/`, {
            email: email,
            password: password,
        });
    }

    sendPlanToDriver() {
        return this.api.post(`${this.url}send_plan_to_driver/`);
    }

    getExternalDataSources() {
        return ExternalDriverDataSourceList;
    }
    getSourceTypes() {
        return ['activeTachoEvent', 'activeMessage']
    }
}

export class DriverStore extends Store<Driver> {
    Model = Driver;
    static backendResourceName = 'driver';
}
