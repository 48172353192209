export const TRANSICS = 'transics';
export const VOLVO = 'volvo';
export const FLEETBOARD = 'fleetboard';
export const DRIVERAPP = 'driver_app';

export const ExternalDataSourceList = [TRANSICS, VOLVO, FLEETBOARD, DRIVERAPP];
export const ExternalTruckDataSourceList = [TRANSICS, VOLVO, FLEETBOARD, DRIVERAPP];
export const ExternalDriverDataSourceList = [VOLVO, FLEETBOARD, DRIVERAPP];
export default { TRANSICS, VOLVO, FLEETBOARD, DRIVERAPP }

